export const getDaysDifference = (date1: Date, date2: Date): number => {
	let startDate: Date = date1
	let endDate: Date = date2

	if (date1 > date2) {
		startDate = date2
		endDate = date1
	}

	const timeDifferenceMs: number = endDate.getTime() - startDate.getTime()

	return Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24))
}
