import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'
import { NullableString } from '@core/types/nullable'
import { InvoiceRead, InvoicesService, TranslationRead } from '@app/generated'
import { LanguageCode } from '@core/enums/language-code.enum'
import { ModalService } from '@core/services/modal.service'

@Component({
	selector: 'app-invoice-description',
	templateUrl: './invoice-description.component.html',
	styleUrl: './invoice-description.component.scss',
})
export class InvoiceDescriptionComponent {
	@Input() invoice!: InvoiceRead
	@Input() language!: string
	protected readonly textDescription = $localize`Invoice description`
	control: FormControl<NullableString> = new FormControl<NullableString>(null)
	confirmButtonText = $localize`confirm`
	cancelButtonText = $localize`cancel`

	constructor(
		private invoicesService: InvoicesService,
		private modalService: ModalService,
	) {}

	ngOnInit() {
		this.fetchData()
	}

	fetchData() {
		this.invoicesService.getInvoiceDescriptions().subscribe({
			next: data => {
				let set: boolean = false
				for (const item of data) {
					if (item.countries?.includes(this.invoice.customer_country_code.toUpperCase() ?? '')) {
						this.control.setValue(
							this.language == 'en'
								? (item.content ?? null)
								: this.getItemTranslationFromLang(item, this.language as LanguageCode),
						)
						set = true
						break
					}
				}
				if (!set) {
					for (const item of data) {
						if (!item.countries || item.countries?.length < 1) {
							this.control.setValue(
								this.language == 'en'
									? (item.content ?? null)
									: this.getItemTranslationFromLang(item, this.language as LanguageCode),
							)
							set = true
							break
						}
					}
				}
			},
		})
	}

	getItemTranslationFromLang(item: any, lang: LanguageCode) {
		const defaultValue = (): string => {
			if (item && item?.text) {
				return item.text
			}

			if (item && item?.value) {
				return item.value
			}

			return ''
		}

		if (!lang || lang?.split('-')[0].toLowerCase() == 'en') {
			return defaultValue()
		}

		if (item && typeof item == 'object' && item?.translations) {
			const trans = item.translations.find(
				(elem: TranslationRead) => elem.language_code.toLowerCase() == lang.split('-')[0].toLowerCase(),
			)
			if (trans) {
				return trans.text
			}
		}

		return defaultValue()
	}

	confirm() {
		this.modalService.modalRef.close(this.control.value ?? '')
	}

	cancel() {
		this.modalService.modalRef.close(null)
	}
}
