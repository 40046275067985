<epic-form-field [text]="textDescription">
	<epic-textarea [control]="control" />
</epic-form-field>
<div class="row-reverse margin__top--large">
	<epic-button
		[text]="confirmButtonText"
		(onClick)="confirm()" />
	<epic-button
		class="margin__right--medium"
		[text]="cancelButtonText"
		[isActive]="false"
		(onClick)="cancel()" />
</div>
