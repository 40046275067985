import { Component, OnInit, ViewChild } from '@angular/core'
import { LoaderService } from '@core/services/loader.service'
import { AppService } from '@app/general/services/app.service'
import {
	CashReceiptRead,
	ContractorRead,
	ContractorReadList,
	ContractorsService,
	CostInvoiceRead,
	EntityRead,
	EntityReadList,
	InvoiceRead,
	InvoiceReadWithCashReceipts,
	InvoicesService,
	InvoiceTypeKey,
	IssuerRead,
	PaymentStatusRead,
	SortOrder,
} from '@app/generated'
import { finalize, forkJoin, Observable, of, Subscription } from 'rxjs'
import { calculateNetValueFromUnitNetPrice } from '@app/invoices/utils/calculate-net-value-from-unit-net-price'
import { calculateSum } from '@app/invoices/utils/calculate-sum'
import { calculateTaxValueFromNetValue } from '@app/invoices/utils/calculate-tax-value-from-net-value'
import { calculateDivision } from '@app/invoices/utils/calculate-division'
import { LanguageService } from '@core/services/language.service'
import { Router } from '@angular/router'
import { getDaysDifference } from '@app/general/utils/get-days-difference'
import { LanguageCode } from '@core/enums/language-code.enum'
import { SignaComponent } from '@app/general/classes/signa-component'
import { config } from '@config'
import { InvoiceType } from '@app/general/enums/invoice-type'
import { InvoicesHelperService } from '@app/invoices/services/invoices-helper.service'
import { Pagination } from '@core/classes/pagination'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { NullableDate, NullableString } from '@core/types/nullable'
import { formatDate } from '@app/general/utils/format-date'
import { TableQuery } from '@core/types/table-query'
import { NotificationService } from '@core/services/notification.service'
import { ModalService } from '@core/services/modal.service'
import { CsvExportService } from '@app/orders/services/csv-export.service'
import { ChangeInvoiceStatusComponent } from '@app/invoices/pages/cost-invoices/change-status/change-invoice-status.component'
import { ModalComponent } from '@core/components/modal/modal.component'
import { InvoiceItemRead } from '@app/generated/model/invoiceItemRead'
import { Permission } from '@app/acl/enums/permissions.enum'
import { setDecimalPlaces } from '@app/invoices/utils/set-decimal-places'
import { CurrencyCode } from '@core/enums/currency.enum'
import { TableComponent } from '@core/components/table/table.component'
import { ScrollItem } from '@app/orders/components/order-list/order-list.component'
import { LayoutService } from '@core/services/layout.service'
import { InvoiceDescriptionComponent } from '@app/invoices/components/invoice-description/invoice-description.component'

export const InvoiceFiltersLocalStorageKey = 'invoice-view-list-filters'
export const InvoiceListScrollStorageKey = 'invoice-view-list-scroll'

interface FiltersFormScheme {
	search: FormControl<NullableString>
	issueDateFrom: FormControl<NullableDate>
	issueDateTo: FormControl<NullableDate>
	issuerID: FormControl<NullableString>
	paymentStatusID: FormControl<NullableString>
	invoiceType: FormControl<InvoiceTypeKey | null>
	entity: FormControl<EntityRead[] | null>
	contractor: FormControl<ContractorRead[] | null>
}

export type InvoiceFiltersValues = {
	[P in keyof FiltersFormScheme]: FiltersFormScheme[P]['value']
}
export type InvoiceFiltersAndSearchValues = {
	filters: InvoiceFiltersValues | undefined
	sortBy?: SortBy
	sortOrder?: 'asc' | 'desc'
	perPage?: number
	pageNumber?: number
}

type SortBy =
	| 'created_at'
	| 'updated_at'
	| 'deleted_at'
	| 'issuer_name'
	| 'customer_name'
	| 'document_number'
	| 'issue_date'

@Component({
	selector: 'app-invoices',
	templateUrl: './invoices.page.html',
	styleUrls: ['./invoices.page.scss', '../../invoices.module.scss'],
})
export class InvoicesPage extends SignaComponent implements OnInit {
	@ViewChild('tableComponent') tableComponent?: TableComponent

	items: InvoiceReadWithCashReceipts[] = []
	selectedItems: InvoiceReadWithCashReceipts[] = []
	languageCode: LanguageCode = LanguageCode.English
	pagination: Pagination = new Pagination()
	filtersFormGroup!: FormGroup<FiltersFormScheme>
	issuers: IssuerRead[] = []
	paymentStatuses: PaymentStatusRead[] = []
	sortOrderFormControl: FormControl<'desc' | 'asc' | null> = new FormControl(null)
	sortByFormControl: FormControl<SortBy | null> = new FormControl(null)

	summaryCurrency: CurrencyCode = CurrencyCode.EUR

	scrollY: number = 0

	contractors: ContractorReadList = { total: 0, items: [] }
	entities: EntityReadList = { total: 0, items: [] }

	refreshFilters: boolean = false
	isLoaded: boolean = false

	dateControl = new FormControl(new Date())

	private filtersValue = this.filtersFormGroup?.value

	protected readonly textInvoiceWithVAT = $localize`Invoice`
	protected readonly textInvoiceWithoutVAT = $localize`Invoice without VAT`
	protected readonly textProformaInvoice = $localize`Proforma Invoice`
	protected readonly textCorrection = $localize`Correction`
	protected readonly textIssueAnInvoice = $localize`Issue an invoice`
	protected readonly textIssuer = $localize`Issuer`
	protected readonly textInvoiceType = $localize`Invoice type`
	protected readonly textSearch = $localize`Search`
	protected readonly textDateOfIssue = $localize`Date of issue`
	protected readonly textFrom = $localize`From`
	protected readonly textTo = $localize`To`
	protected readonly textPaymentStatus = $localize`Payment status`
	protected readonly textShowAll = $localize`Show all`
	protected readonly textRemoveFilters = $localize`Clear filters`
	protected readonly textDelete = $localize`Delete`
	protected readonly textDownloadDataInCsvFormat = $localize`Download data in CSV format.`
	protected readonly textDraftTooltip = $localize`An invoice with the "draft" status, also known as a draft invoice, is a form of a temporary invoice that has not yet been formally issued.`
	protected readonly textInvoiceDetails = $localize`Invoice details`
	protected readonly InvoiceType = InvoiceType
	protected readonly textDownloadPdf = $localize`Download PDF`
	protected readonly textIsCreatedAutomatically = $localize`This invoice has been automatically generated based on the established price list`
	protected readonly textCreateCashDisbursement = $localize`Create cash disbursement`
	protected readonly textChangeSummaryCurrency = $localize`Change summary currency`
	protected readonly textDuplicateDocument = $localize`Duplicate document`
	protected readonly textContractorGroup = $localize`Contractor group`
	protected readonly textEntity = $localize`Contractor`
	protected readonly textSearchRecipient = $localize`Search`
	protected readonly textEnterPaymentDate = $localize`Enter payment date`
	protected readonly textSelect = $localize`Select`

	private subscription?: Subscription

	constructor(
		private loaderService: LoaderService,
		private appService: AppService,
		private invoicesService: InvoicesService,
		private languageService: LanguageService,
		private router: Router,
		public invoicesHelperService: InvoicesHelperService,
		private notificationService: NotificationService,
		private modalService: ModalService,
		private csvExportService: CsvExportService,
		private contractorService: ContractorsService,
		private layoutService: LayoutService,
	) {
		super()
		this.appService.title = $localize`Sell documents`
		this.languageCode = this.languageService.getLocalStorageLanguage as LanguageCode // :)
	}

	/**
	 * Retrieves the selected issuer based on the provided issuer ID value.
	 *
	 * @returns {IssuerRead|undefined} The selected issuer object if found, otherwise undefined.
	 */
	get getSelectedIssuer(): IssuerRead | undefined {
		return this.issuers.find(i => i.id === this.filtersFormGroup.controls.issuerID.value)
	}

	/**
	 * Gets the selected payment status.
	 *
	 * @returns {PaymentStatusRead | undefined} The selected payment status or undefined if not found.
	 */
	get getSelectedPaymentStatus(): PaymentStatusRead | undefined {
		return this.paymentStatuses.find(i => i.id === this.filtersFormGroup.controls.paymentStatusID.value)
	}

	/**
	 * Returns the selected invoice type based on the specified invoice type value.
	 *
	 * @returns {string} The selected invoice type.
	 */
	get getSelectedInvoiceType(): string {
		switch (this.filtersFormGroup.controls.invoiceType.value) {
			case InvoiceType.WithTax:
				return this.textInvoiceWithVAT
			case InvoiceType.WithoutTax:
				return this.textInvoiceWithoutVAT
			case InvoiceType.Correction:
				return this.textCorrection
			case InvoiceType.Proforma:
				return this.textProformaInvoice
			default:
				return this.textInvoiceWithVAT
		}
	}

	get availableCurrencies(): CurrencyCode[] {
		return Array.from(new Set(this.items.map(i => i.currency as CurrencyCode)))
	}

	ngOnInit(): void {
		this.initFilters()
		const filters = this.getLocalStorageFilterAndSortInvoice
		this.filtersValue = JSON.parse(JSON.stringify(this.filtersFormGroup.value))

		const scroll = this.getLocalStorageScroll

		if (scroll?.scrollY) {
			const interval = setInterval(() => {
				if (this.isLoaded) {
					this.layoutService.layoutContainer?.nativeElement.scrollTo({
						top: scroll.scrollY ?? 0,
					})
					clearInterval(interval)
				}
			}, 300)
		}

		this.pagination.currentPageChanges().subscribe(() => {
			if (this.isLoaded) {
				sessionStorage.removeItem(InvoiceListScrollStorageKey)
			}
		})

		this.layoutService.afterScroll.subscribe(event => {
			const target = event!.target as HTMLElement

			if (target) {
				this.scrollY = target.scrollTop
			}
		})

		if (filters?.filters) {
			this.filtersFormGroup.controls.search.setValue('')
			this.filtersFormGroup.patchValue(filters.filters)
			this.sortByFormControl.patchValue((filters.sortBy as SortBy) ?? '')
			this.sortOrderFormControl.patchValue((filters.sortOrder as SortOrder) ?? '')
			this.pagination.setPerPage(filters.perPage ?? 25)
		}

		this.fetchContractors()
		this.fetchEntities()
		this.subscription = this.pagination.listShouldBeReloaded().subscribe(() => {
			this.selectedItems = []
			this.fetchData()
		})
	}

	getNetValue(items?: InvoiceItemRead[], itemsOfTheCorrectedInvoice?: InvoiceItemRead[]) {
		const components: number[] = []

		if (items && items.length > 0) {
			for (const item of items) {
				components.push(
					calculateNetValueFromUnitNetPrice({
						netPrice: item.net_unit_price ?? 0,
						quantity: item.quantity ?? 1,
					}),
				)
			}
		}

		const result = calculateSum(components)

		const componentsOfTheCorrectedInvoice: number[] = []

		if (itemsOfTheCorrectedInvoice) {
			for (const item of itemsOfTheCorrectedInvoice) {
				componentsOfTheCorrectedInvoice.push(
					calculateNetValueFromUnitNetPrice({
						netPrice: item.net_unit_price ?? 0,
						quantity: item.quantity ?? 1,
					}),
				)
			}
			return setDecimalPlaces(result - calculateSum(componentsOfTheCorrectedInvoice), 2)
		}

		return result
	}

	getTaxValue(items?: InvoiceItemRead[], itemsOfTheCorrectedInvoice?: InvoiceItemRead[]): number {
		const components: number[] = []

		if (items && items.length > 0) {
			for (const item of items) {
				const netValue = calculateNetValueFromUnitNetPrice({
					netPrice: item.net_unit_price ?? 0,
					quantity: item.quantity ?? 1,
				})
				if (item.tax_rate?.value !== undefined && item.tax_rate?.value !== null) {
					components.push(
						calculateTaxValueFromNetValue({
							netValue: netValue,
							taxRate: calculateDivision(item.tax_rate.value, 100),
						}),
					)
				}
			}
		}

		const result = calculateSum(components)

		const componentsOfTheCorrectedInvoice: number[] = []

		if (itemsOfTheCorrectedInvoice) {
			for (const item of itemsOfTheCorrectedInvoice) {
				const netValue = calculateNetValueFromUnitNetPrice({
					netPrice: item.net_unit_price ?? 0,
					quantity: item.quantity ?? 1,
				})

				if (item.tax_rate?.value !== undefined && item.tax_rate?.value !== null) {
					componentsOfTheCorrectedInvoice.push(
						calculateTaxValueFromNetValue({
							netValue: netValue,
							taxRate: calculateDivision(item.tax_rate.value, 100),
						}),
					)
				}
			}

			return setDecimalPlaces(result - calculateSum(componentsOfTheCorrectedInvoice), 2)
		}

		return result
	}

	getGrossValue(items?: InvoiceItemRead[], itemsOfTheCorrectedInvoice?: InvoiceItemRead[]): number {
		const netValue = this.getNetValue(items, itemsOfTheCorrectedInvoice)
		const taxValue = this.getTaxValue(items, itemsOfTheCorrectedInvoice)

		return calculateSum([netValue, taxValue])
	}

	getNetValueSum(): number {
		const components: number[] = []
		const items = this.items.filter(i => i.currency === this.summaryCurrency)
		for (const item of items) {
			if (item.items && item.items.length > 0) {
				if (item.items && item.items.length > 0) {
					components.push(this.getNetValue(item.items, item.relates_to?.items))
				}
			}
		}

		return calculateSum(components)
	}

	getTaxValueSum(): number {
		const components: number[] = []

		const items = this.items.filter(i => i.currency === this.summaryCurrency)
		for (const item of items) {
			if (item.items && item.items.length > 0) {
				components.push(this.getTaxValue(item.items, item.relates_to?.items))
			}
		}

		return calculateSum(components)
	}

	getGrossValueSum(): number {
		const components: number[] = []

		const items = this.items.filter(i => i.currency === this.summaryCurrency)
		for (const item of items) {
			if (item.items && item.items.length > 0) {
				components.push(this.getGrossValue(item.items, item.relates_to?.items))
			}
		}

		return calculateSum(components)
	}

	handleInvoiceDetails(id: string): void {
		this.setLocalStorageScroll(id)
		this.loaderService.setContain().enable(async () => {
			await this.router.navigateByUrl(`/${config.modules.invoices.path}/${id}`)
		})
	}

	canCreateCashDisbursement(invoice: InvoiceRead): boolean {
		if (!invoice.relates_to) {
			return false
		} else {
			return this.getGrossValue(invoice.items, invoice.relates_to.items) < 0
		}
	}

	createCashDisbursement(invoice: InvoiceRead): void {
		this.loaderService.setContain().enable(async () => {
			await this.router.navigate(['/cash-documents/cash-disbursement/create'], {
				queryParams: {
					invoice: invoice.id,
				},
			})
		})
	}

	getDaysDifference(item: InvoiceRead): number {
		if (item.payment_status?.is_paid) {
			return 0
		}

		if (!item.payment_due_date) {
			return 0
		}

		const dueDate: Date = new Date(item.payment_due_date)
		const now: Date = new Date()

		if (now < dueDate) {
			return 0
		}

		return getDaysDifference(dueDate, now)
	}

	async downloadPDF(invoice: InvoiceRead, language: string): Promise<void> {
		const modal = await this.modalService.open(InvoiceDescriptionComponent)
		modal.title = $localize`Assign invoice description`
		modal.iconName = 'link-alt'
		modal.componentInstance.invoice = invoice
		modal.componentInstance.language = language
		modal.afterClosed.subscribe(value => {
			if (value != null) {
				this.invoicesHelperService.downloadPdf(invoice, language, value)
			}
		})
	}

	async sendAnInvoice(invoice: InvoiceRead, language: string): Promise<void> {
		await this.invoicesHelperService.sendAnInvoice(invoice, language)
	}

	/**
	 * Creates an invoice based on the provided invoice type.
	 *
	 * @param {InvoiceType} invoiceType - The type of invoice.
	 * @returns {void}
	 */
	createInvoice(invoiceType: InvoiceType): void {
		this.loaderService.setContain().enable(async () => {
			if (invoiceType === InvoiceType.Proforma) {
				await this.router.navigateByUrl(`/${config.modules.invoices.path}/create/proforma`)
			} else if (invoiceType === InvoiceType.WithoutTax) {
				await this.router.navigateByUrl(`/${config.modules.invoices.path}/create/tax-free`)
			} else {
				await this.router.navigateByUrl(`/${config.modules.invoices.path}/create`)
			}
		})
	}

	/**
	 * Logs the value of the filters form group and reloads the list using pagination.
	 *
	 * @returns {void}
	 */
	confirmFilters(): void {
		this.pagination.reloadList()
	}

	showContractor(invoice: InvoiceRead): void {
		if (invoice.customer_id) {
			this.loaderService.setContain().enable(async () => {
				await this.router.navigateByUrl(`${this.appConfig.modules.contractors.path}/${invoice.customer_id}`)
			})
		}
	}

	handleQueryChanges(value: TableQuery): void {
		if (value.sort) {
			this.loaderService.setContain().enable(() => {
				this.sortByFormControl.patchValue((value.sort?.sortKey as SortBy) ?? null, { emitEvent: false })
				this.sortOrderFormControl.patchValue(value.sort?.sortDir ?? null, { emitEvent: false })
				this.pagination.reloadList()
			})
		} else {
			this.sortByFormControl.patchValue(null)
			this.sortOrderFormControl.patchValue(null)
		}
		this.loaderService.setContain().enable(this.fetchData.bind(this))
	}

	handleCheckboxesChanges(value: InvoiceReadWithCashReceipts[]): void {
		this.selectedItems = value
	}

	exportToCSV(): void {
		if (this.selectedItems.length) {
			const headers: string[] = [
				$localize`Issuer`,
				$localize`Document number`,
				$localize`Issue date`,
				$localize`Contractor`,
				$localize`Payment status`,
				$localize`Net`,
				$localize`VAT`,
				$localize`Gross`,
				$localize`Date`,
				$localize`Amount`,
				$localize`Amount due`,
			]
			const elements: any[] = []
			let net_value
			let tax_value
			let gross_value
			this.selectedItems.forEach(elem => {
				let firstRowGenerated = false
				if (elem.cash_receipts) {
					let index = 0
					for (const receipt of elem.cash_receipts) {
						if (index++ === 0 && !firstRowGenerated) {
							firstRowGenerated = true
							if (elem.invoice_type?.key == InvoiceType.Correction) {
								net_value = this.getNetValue(elem.items) - this.getNetValue(elem.relates_to?.items)
								tax_value = this.getTaxValue(elem.items) - this.getTaxValue(elem.relates_to?.items)
								gross_value = this.getGrossValue(elem.items) - this.getGrossValue(elem.relates_to?.items)
							} else {
								net_value = this.getNetValue(elem.items)
								tax_value = this.getTaxValue(elem.items)
								gross_value = this.getGrossValue(elem.items)
							}

							elements.push([
								elem.issuer_name,
								elem.document_number,
								elem.issue_date,
								elem.customer_name,
								this.getItemTranslation(elem.payment_status),
								net_value.toFixed(2).replace('.', ','),
								tax_value.toFixed(2).replace('.', ','),
								gross_value.toFixed(2).replace('.', ','),
								receipt.issue_date,
								receipt.net_value,
								this.getValueToPay(elem).toFixed(2).replace('.', ','),
							])
						} else {
							elements.push(['', '', '', '', '', '', '', '', receipt.issue_date, receipt.net_value, ''])
						}
					}
				}
				if (!firstRowGenerated) {
					if (elem.invoice_type?.key == InvoiceType.Correction) {
						net_value = this.getNetValue(elem.items) - this.getNetValue(elem.relates_to?.items)
						tax_value = this.getTaxValue(elem.items) - this.getTaxValue(elem.relates_to?.items)
						gross_value = this.getGrossValue(elem.items) - this.getGrossValue(elem.relates_to?.items)
					} else {
						net_value = this.getNetValue(elem.items)
						tax_value = this.getTaxValue(elem.items)
						gross_value = this.getGrossValue(elem.items)
					}
					elements.push([
						elem.issuer_name,
						elem.document_number,
						elem.issue_date,
						elem.customer_name,
						this.getItemTranslation(elem.payment_status),
						net_value.toFixed(2).replace('.', ','),
						tax_value.toFixed(2).replace('.', ','),
						gross_value.toFixed(2).replace('.', ','),
						'',
						0,
						this.getValueToPay(elem).toFixed(2).replace('.', ','),
					])
				}
			})

			// Eksport do CSV
			this.csvExportService.exportToCsv(elements, headers, $localize`invoices` + '.csv')
		} else {
			this.notificationService.error($localize`Requires selecting one or more invoices.`)
		}
	}

	aclCanCreateInvoices(): boolean {
		const permissions = [Permission.INVOICES_CREATE]
		return this.aclService.hasPermission(permissions)
	}

	aclCanUpdateInvoices(): boolean {
		const permissions = [Permission.INVOICES_UPDATE]
		return this.aclService.hasPermission(permissions)
	}

	aclCanDeleteInvoices(): boolean {
		const permissions = [Permission.INVOICES_DELETE]
		return this.aclService.hasPermission(permissions)
	}

	aclCanReadIssuer(): boolean {
		const permissions = [Permission.ISSUERS_READ]
		return this.aclService.hasPermission(permissions)
	}

	aclCanReadInvoiceType(): boolean {
		const permissions = [Permission.INVOICE_TYPES_READ]
		return this.aclService.hasPermission(permissions)
	}

	aclCanReadStatuses(): boolean {
		const permissions = [Permission.PAYMENT_STATUSES_READ]
		return this.aclService.hasPermission(permissions)
	}

	aclCanReadPaymentStatus(): boolean {
		const permissions = [Permission.PAYMENT_STATUSES_READ]
		return this.aclService.hasPermission(permissions)
	}

	async edit(invoice: InvoiceRead): Promise<void> {
		if (invoice.status != 'DRAFT') {
			;(
				await this.modalService.confirmation({
					title: $localize`Attempt to Edit Issued Invoice`,
					message: $localize`You are attempting to edit an invoice that has already been issued. This operation may result in irreversible changes to your accounting records. Are you sure you want to proceed?`,
					confirmButtonText: $localize`Proceed`,
					cancelButtonText: $localize`Cancel`,
				})
			).subscribe(async value => {
				if (value) {
					this.loaderService.setContain().enable(async () => {
						await this.router.navigateByUrl(`/${config.modules.invoices.path}/${invoice.id}/update`)
					})
				}
			})
		} else {
			this.loaderService.setContain().enable(async () => {
				await this.router.navigateByUrl(`/${config.modules.invoices.path}/${invoice.id}/update`)
			})
		}
	}

	async delete(invoice: InvoiceRead) {
		;(await this.modalService.confirmation()).subscribe(value => {
			if (value) {
				this.loaderService.setContain().enable(() => {
					this.invoicesService
						.deleteInvoice(invoice.id)
						.pipe(finalize(() => this.pagination.reloadList()))
						.subscribe({
							error: () => {
								this.notificationService.error($localize`There was a problem deleting the invoice`)
							},
						})
				})
			}
		})
	}

	async deleteAll(): Promise<void> {
		if (this.selectedItems.length) {
			;(await this.modalService.confirmation()).subscribe((value: boolean) => {
				if (value) {
					const request = this.selectedItems.map(elem => this.invoicesService.deleteInvoice(elem.id))
					this.loaderService.setContain().enable(() => {
						forkJoin(request)
							.pipe(finalize(() => this.pagination.reloadList()))
							.subscribe({
								error: () => {
									this.notificationService.error($localize`Failed to delete all the indicated invoices.`)
								},
							})
					})
				}
			})
		} else {
			this.notificationService.error($localize`Requires selecting one or more invoices.`)
		}
	}

	async changeStatus(invoice: InvoiceRead) {
		const modal: ModalComponent<ChangeInvoiceStatusComponent> =
			await this.modalService.open(ChangeInvoiceStatusComponent)
		modal.iconName = 'status-change'
		modal.title = $localize`Change the payment status of the invoice`
		modal.componentInstance.statuses = this.paymentStatuses
		modal.componentInstance.formControl.patchValue(invoice.payment_status?.id)
		modal.componentInstance.invoice = invoice
		modal.afterClosed.subscribe((result?: InvoiceRead) => {
			if (result) {
				const index = this.items.findIndex(elem => elem.id == result.id)
				this.invoicesService.getCashReceiptsFromInvoice(result.id).subscribe({
					next: response => {
						this.items[index] = { ...result, cash_receipts: response }
					},
				})
			}
		})
	}

	createCashReceipt(model: InvoiceRead): void {
		this.loaderService.setContain().enable(async () => {
			await this.router.navigate(['/cash-documents/cash-receipts/create'], {
				queryParams: {
					invoice: model.id,
				},
			})
		})
	}

	onSummaryCurrencyClick(currency: CurrencyCode): void {
		this.summaryCurrency = currency
	}

	duplicateDocument(item: InvoiceRead): void {
		this.loaderService.enable(async () => {
			await this.router.navigateByUrl(`/${config.modules.invoices.path}/${item.id}/duplicate`)
		})
	}

	/**
	 * Fetches data from the server using specified filters.
	 *
	 * @private
	 * @returns {void}
	 */
	private fetchData(): void {
		this.loaderService.setContain().enable(() => {
			const filters = this.filtersFormGroup.value
			this.setInvoiceLocalStorageFiltersAndSort()
			this.filtersValue = JSON.parse(JSON.stringify(filters))
			let issueDateTo: string | undefined
			let issueDateFrom: string | undefined

			if (filters.issueDateFrom instanceof Date) {
				issueDateFrom = formatDate(filters.issueDateFrom)
			} else if (filters.issueDateFrom) {
				issueDateFrom = formatDate(new Date(filters.issueDateFrom))
			}
			if (filters.issueDateTo instanceof Date) {
				issueDateTo = formatDate(filters.issueDateTo)
			} else if (filters.issueDateTo) {
				issueDateTo = formatDate(new Date(filters.issueDateTo))
			}
			forkJoin({
				issuers: this.getIssuers(),
				paymentStatuses: this.getPaymentStatuses(),
				invoices: this.invoicesService.getInvoices(
					this.pagination.offset(),
					this.pagination.perPage,
					filters.search ?? undefined,
					filters.issueDateFrom ? issueDateFrom : undefined,
					filters.issueDateTo ? issueDateTo : undefined,
					filters.issuerID ?? undefined,
					filters.invoiceType ?? undefined,
					undefined,
					undefined,
					filters.paymentStatusID ?? undefined,
					undefined,
					this.sortOrderFormControl.value ? this.sortOrderFormControl.value : undefined,
					this.sortByFormControl.value ? this.sortByFormControl.value : undefined,
					undefined,
					this.filtersFormGroup.controls.entity.value?.map(value => value.id) ?? undefined,
					this.filtersFormGroup.controls.contractor.value?.map(value => value.id) ?? undefined,
				),
			})
				.pipe(finalize(this.loaderService.disable))
				.subscribe({
					next: response => {
						this.issuers = response.issuers
						this.paymentStatuses = response.paymentStatuses
						this.pagination.setTotal(response.invoices.total)
						if (!this.isLoaded) {
							const filters = this.getLocalStorageFilterAndSortInvoice

							if (filters) {
								this.pagination.setCurrentPage(filters.pageNumber ?? 1)
							}
							this.isLoaded = true
						}
						this.setInvoiceLocalStoragePageNumber()
						this.items = response.invoices.items
					},
				})
		})
	}

	/**
	 * Retrieves the list of issuers.
	 *
	 * @returns {Observable<IssuerRead[]>} The list of issuers as an Observable.
	 */
	private getIssuers(): Observable<IssuerRead[]> {
		if (this.issuers.length) {
			return of(this.issuers)
		} else {
			return this.invoicesService.getIssuers()
		}
	}

	/**
	 * Returns the payment statuses as an Observable of an array of PaymentStatusRead objects.
	 * If the payment statuses have already been loaded, it returns them from memory.
	 * Otherwise, it retrieves the payment statuses from the invoicesService.
	 *
	 * @returns {Observable<PaymentStatusRead[]>} - An Observable of an array of PaymentStatusRead objects.
	 */
	private getPaymentStatuses(): Observable<PaymentStatusRead[]> {
		if (this.paymentStatuses.length) {
			return of(this.paymentStatuses)
		} else {
			return this.invoicesService.getPaymentStatuses()
		}
	}

	aclCanReadContractors(): boolean {
		const permissions = [Permission.CONTRACTORS_READ]
		return this.aclService.hasPermission(permissions)
	}

	aclCanReadEntities(): boolean {
		const permissions = [Permission.ENTITIES_READ]
		return this.aclService.hasPermission(permissions)
	}

	fetchContractors(searchTerm?: string): void {
		this.contractorService.getContractors(0, 15, searchTerm ? encodeURIComponent(searchTerm) : undefined).subscribe({
			next: (response: ContractorReadList) => {
				this.contractors = response
			},
			error: error => {
				if (error.code == 422) {
					console.error('invalid query on fetch request')
				}
				const errorMessage = $localize`Error during fetching contractors`
				this.notificationService.error(errorMessage)
			},
		})
	}

	fetchEntities(searchTerm?: string): void {
		if (!this.aclCanReadEntities()) {
			return
		}
		this.contractorService.getEntities(0, 15, searchTerm ? encodeURIComponent(searchTerm) : undefined).subscribe({
			next: (response: EntityReadList) => {
				this.entities = response
			},
			error: error => {
				if (error.code == 422) {
					console.error('invalid query on fetch request')
				}
				const errorMessage = $localize`Error during fetching contractors`
				this.notificationService.error(errorMessage)
			},
		})
	}

	getValueCashReceipt(items: CashReceiptRead[] | null): number {
		if (items) {
			let totalValue = 0
			const components: number[] = []

			for (const item of items) {
				if (item.tax_rate?.value !== undefined && item.tax_rate?.value !== null) {
					components.push(
						calculateTaxValueFromNetValue({
							netValue: item.net_value,
							taxRate: calculateDivision(item.tax_rate.value, 100),
						}),
					)
				}
				totalValue += item.net_value
			}

			return calculateSum([totalValue, calculateSum(components)], 2)
		}
		return 0
	}

	getValueToPay(invoice: InvoiceReadWithCashReceipts) {
		const grossValue = this.getGrossValue(invoice.items)
		const grossReceiptsValue = this.getValueCashReceipt(invoice.cash_receipts)
		if (invoice.relates_to) {
			const realatedInvoiceValue = this.getGrossValue(invoice.relates_to.items)
			return grossValue - grossReceiptsValue - realatedInvoiceValue
		}

		return grossValue - grossReceiptsValue
	}

	getValueToPaySum() {
		let totalValue = 0
		const items = this.items.filter(i => i.currency === this.summaryCurrency)
		for (const invoice of items) {
			totalValue += this.getValueToPay(invoice)
		}
		return totalValue
	}

	private initFilters() {
		this.filtersFormGroup = new FormGroup<FiltersFormScheme>({
			search: new FormControl<NullableString>(null, [Validators.maxLength(128)]),
			issueDateFrom: new FormControl<NullableDate>(null),
			issueDateTo: new FormControl<NullableDate>(null),
			issuerID: new FormControl<NullableString>(null),
			paymentStatusID: new FormControl<NullableString>(null),
			invoiceType: new FormControl<InvoiceTypeKey | null>(null),
			entity: new FormControl<EntityRead[] | null>(null),
			contractor: new FormControl<ContractorRead[] | null>(null),
		})
	}

	handleClearFilters() {
		this.refreshFilters = true
		this.initFilters()
		setTimeout(() => {
			this.refreshFilters = false
		})
		this.pagination.reset()
		sessionStorage.removeItem(InvoiceFiltersLocalStorageKey)
		this.loaderService.setContain().enable(this.fetchData.bind(this))
	}

	get getLocalStorageFilterAndSortInvoice(): InvoiceFiltersAndSearchValues | undefined {
		const storedFilters = sessionStorage.getItem(InvoiceFiltersLocalStorageKey)

		if (!storedFilters) {
			return undefined
		}

		return JSON.parse(storedFilters)
	}

	setInvoiceLocalStorageFiltersAndSort(): void {
		const filters = this.getLocalStorageFilterAndSortInvoice
		sessionStorage.setItem(
			InvoiceFiltersLocalStorageKey,
			JSON.stringify({
				filters: this.filtersFormGroup.value ?? null,
				sortOrder: this.sortOrderFormControl.value ?? null,
				sortBy: this.sortByFormControl.value ?? null,
				perPage: this.pagination.perPage,
				pageNumber: filters?.pageNumber ?? 1,
			}),
		)
	}

	setInvoiceLocalStoragePageNumber(): void {
		sessionStorage.setItem(
			InvoiceFiltersLocalStorageKey,
			JSON.stringify({
				filters: this.filtersFormGroup.value ?? null,
				sortOrder: this.sortOrderFormControl.value ?? null,
				sortBy: this.sortByFormControl.value ?? null,
				perPage: this.pagination.perPage,
				pageNumber: this.pagination.currentPage,
			}),
		)
	}

	setTableViewSortValue(): void {
		setTimeout(() => {
			if (!!this.tableComponent && !!this.sortByFormControl && !!this.sortOrderFormControl) {
				this.tableComponent.setTableSortValue(
					this.sortByFormControl.value ?? '',
					this.sortOrderFormControl.value ?? 'desc',
				)
			}
		}, 0)
	}

	checkIfContractorGroupSelected(contractor: ContractorRead) {
		if (this.filtersFormGroup.controls.contractor.value) {
			for (const filter of this.filtersFormGroup.controls.contractor.value) {
				if (contractor.id === filter.id) return true
			}
		}
		return false
	}

	checkIfContractorSelected(entity: EntityRead) {
		if (this.filtersFormGroup.controls.entity.value) {
			for (const filter of this.filtersFormGroup.controls.entity.value) {
				if (entity.id === filter.id) return true
			}
		}
		return false
	}

	createTransfer(model: InvoiceReadWithCashReceipts) {
		this.loaderService.setContain().enable(async () => {
			await this.router.navigate(['/cash-documents/transfer-incoming/create'], {
				queryParams: {
					invoice: model.id,
				},
			})
		})
	}

	getValuePaidSum() {
		let totalValue = 0
		const items = this.items.filter(i => i.currency === this.summaryCurrency)
		for (const invoice of items) {
			totalValue += this.getValueCashReceipt(invoice.cash_receipts)
		}
		return totalValue
	}

	getValuePaid(invoice: InvoiceReadWithCashReceipts) {
		return this.getValueCashReceipt(invoice.cash_receipts)
	}

	goToOrder(order_id: string) {
		this.loaderService.enable(async () => {
			await this.router.navigateByUrl(`/${config.modules.orders.path}/${order_id}`)
		})
	}

	setLocalStorageScroll(target: string) {
		sessionStorage.setItem(
			InvoiceListScrollStorageKey,
			JSON.stringify({
				scrollY: this.scrollY,
				scrollTargetId: target,
			}),
		)
	}

	get getLocalStorageScroll(): ScrollItem | undefined {
		const storedFilters = sessionStorage.getItem(InvoiceListScrollStorageKey)

		if (!storedFilters) {
			return undefined
		}

		return JSON.parse(storedFilters)
	}

	clearFilters() {
		sessionStorage.removeItem(InvoiceListScrollStorageKey)
	}

	resetDateControls() {
		this.dateControl.setValue(new Date())
	}

	changePaymentDate(item: InvoiceRead) {
		this.dateControl.updateValueAndValidity()
		this.invoicesService
			.patchInvoice(item.id, {
				payment_date: this.dateControl.value?.toISOString().slice(0, 10) ?? null,
			})
			.subscribe({
				next: response => {
					this.items = this.items.map(i =>
						i.id === item.id
							? { ...i, payment_status: response.payment_status, payment_date: response.payment_date }
							: i,
					)
					this.notificationService.success($localize`Payment status and payment date have been changed`)
				},

				error: () => {
					this.notificationService.error($localize`Failed to change payment date`)
				},
			})
	}
}
