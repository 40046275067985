<p
	i18n
	class="font__small">
	Select the payment status for invoice number <b>{{ invoice?.document_number }}</b
	>.
</p>
<form
	#formElement="ngForm"
	(ngSubmit)="confirm()"
	class="margin__top--large">
	<epic-form-field>
		<epic-select [control]="formControl">
			@for (ps of statuses; track ps) {
				<epic-option
					[text]="getItemTranslation(ps)"
					[value]="ps.id" />
			}
		</epic-select>
	</epic-form-field>
	<div class="row-reverse margin__top--large">
		<epic-button type="submit">
			<span i18n> Confirm </span>
		</epic-button>
	</div>
</form>
