import { Component, Input } from '@angular/core'
import { InvoiceRead, InvoicesService, PaymentStatusRead } from '@app/generated'
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { FormFieldComponent } from '@core/components/form-field/form-field.component'
import { SelectComponent } from '@core/components/select/select.component'
import { OptionComponent } from '@core/components/option/option.component'
import { ButtonComponent } from '@core/components/button/button.component'
import { getItemTranslation } from '@app/general/utils/get-item-translation'
import { LoaderService } from '@core/services/loader.service'
import { NotificationService } from '@core/services/notification.service'
import { NullableString } from '@core/types/nullable'
import { ModalService } from '@core/services/modal.service'
import { finalize } from 'rxjs'

@Component({
	selector: 'app-change-status',
	standalone: true,
	imports: [FormFieldComponent, SelectComponent, OptionComponent, ButtonComponent, FormsModule, ReactiveFormsModule],
	templateUrl: './change-invoice-status.component.html',
	styleUrl: './change-invoice-status.component.scss',
})
export class ChangeInvoiceStatusComponent {
	@Input() statuses: PaymentStatusRead[] = []
	@Input() formControl = new FormControl<NullableString | undefined>('', [Validators.required])
	@Input() invoice?: InvoiceRead
	protected readonly getItemTranslation = getItemTranslation

	constructor(
		private invoicesService: InvoicesService,
		private loaderService: LoaderService,
		private notificationService: NotificationService,
		private modalService: ModalService,
	) {}

	confirm(): void {
		if (this.invoice) {
			if (this.formControl.valid) {
				this.loaderService.setContain().enable(() => {
					if (this.invoice) {
						this.invoicesService
							.patchInvoice(this.invoice.id, { payment_status_id: this.formControl.value ?? '' })
							.pipe(finalize(() => this.loaderService.disable()))
							.subscribe({
								next: (response: InvoiceRead) => {
									this.modalService.modalRef.close(response)
								},
								error: () => {
									this.notificationService.error($localize`An error occurred while changing the status.`)
								},
							})
					}
				})
			}
		}
	}
}
