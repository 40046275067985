<span #topPageHtmlElement></span>
<epic-card
	[collapseState]="false"
	class="margin__bottom--large">
	<div
		i18n
		start>
		Filters
	</div>
	<div
		class="row"
		end>
		<epic-icon-button
			(click)="filtersFormGroup.reset(); confirmFilters(); $event.stopPropagation(); clearFilters()"
			[text]="textRemoveFilters"
			hoverColor="var(--color__danger)"
			iconHoverColor="var(--color__white)"
			iconHoverQuerySelector="path"
			iconName="broom" />
	</div>
	<form
		#formElement="ngForm"
		(ngSubmit)="confirmFilters()"
		class="form">
		<div class="form__element-3">
			<epic-form-field [text]="textSearch">
				<epic-input
					[focused]="!!filtersFormGroup.controls.search.value"
					[control]="filtersFormGroup.controls.search"
					(keyup.enter)="formElement.onSubmit($event)"
					[placeholder]="textSearch" />
			</epic-form-field>
		</div>
		<div class="form__element-3">
			@if (aclCanReadIssuer()) {
			<epic-form-field [text]="textIssuer">
				<epic-select
					[focused]="!!filtersFormGroup.controls.issuerID.value"
					[control]="filtersFormGroup.controls.issuerID"
					[customSelectedValues]="true">
					@if (getSelectedIssuer) {
					<div
						class="epic-select__custom-values"
						selected-values>
						<span class="epic-select__value-round">{{ getSelectedIssuer.name }}</span>
					</div>
					}
					<epic-option
						[text]="textShowAll"
						[value]="null" />
					@for (iss of issuers; track iss) {
					<epic-option
						[text]="iss.name"
						[value]="iss.id" />
					}
				</epic-select>
			</epic-form-field>
			}
		</div>
		<div class="form__element-3">
			@if (aclCanReadInvoiceType()) {
			<epic-form-field [text]="textInvoiceType">
				<epic-select
					[focused]="!!filtersFormGroup.controls.invoiceType.value"
					[control]="filtersFormGroup.controls.invoiceType"
					[customSelectedValues]="true">
					<div
						class="epic-select__custom-values"
						selected-values>
						<span class="epic-select__value-round">{{ getSelectedInvoiceType }}</span>
					</div>
					<epic-option
						[text]="textShowAll"
						[value]="null" />
					<epic-option
						[text]="textInvoiceWithVAT"
						[value]="InvoiceType.WithTax" />
					<!--					<epic-option-->
					<!--						[text]="textInvoiceWithoutVAT"-->
					<!--						[value]="InvoiceType.WithoutTax" />-->
					<epic-option
						[text]="textCorrection"
						[value]="InvoiceType.Correction" />
					<epic-option
						[text]="textProformaInvoice"
						[value]="InvoiceType.Proforma" />
				</epic-select>
			</epic-form-field>
			}
		</div>
		<div class="form__element-3">
			<epic-form-field-group
				[text]="textDateOfIssue"
				datePicker>
				<epic-input
					[focused]="!!filtersFormGroup.controls.issueDateFrom.value"
					[control]="filtersFormGroup.controls.issueDateFrom"
					[placeholder]="textFrom" />
				<epic-input
					[focused]="!!filtersFormGroup.controls.issueDateTo.value"
					[control]="filtersFormGroup.controls.issueDateTo"
					[placeholder]="textTo" />
			</epic-form-field-group>
		</div>
		<div class="form__element-3">
			@if (aclCanReadPaymentStatus()) {
			<epic-form-field [text]="textPaymentStatus">
				<epic-select
					[focused]="!!filtersFormGroup.controls.paymentStatusID.value"
					[control]="filtersFormGroup.controls.paymentStatusID"
					[customSelectedValues]="true">
					<div
						*ngIf="getSelectedPaymentStatus"
						class="epic-select__custom-values"
						selected-values>
						<span class="epic-select__value-round">{{ getItemTranslation(getSelectedPaymentStatus) }}</span>
					</div>
					<epic-option
						[selected]="false"
						[text]="textShowAll"
						[value]="null" />
					@for (ps of paymentStatuses; track ps) {
					<epic-option
						[text]="getItemTranslation(ps)"
						[value]="ps.id" />
					}
				</epic-select>
			</epic-form-field>
			}
		</div>
		<div class="form__element-3">
			@if (aclCanReadContractors()) {
			<epic-form-field [text]="textContractorGroup">
				<epic-autocomplete
					[multiSelect]="true"
					[placeholder]="textSearchRecipient"
					(onClick)="fetchContractors($event)"
					(searchChange)="fetchContractors($event)"
					[control]="filtersFormGroup.controls.contractor"
					[customSelectedValues]="true"
					class="form__element-7">
					<div
						class="epic-select__custom-values"
						selected-values>
						@for (contractor of filtersFormGroup.controls.contractor.value; track contractor) {
						<span class="epic-select__value-round">{{ contractor.name }}</span>
						}
					</div>
					@for (contractor of contractors.items; track contractor.id) {
					<epic-option
						[value]="contractor"
						[selected]="checkIfContractorGroupSelected(contractor)"
						class="epic_select__option">
						{{ contractor.name }}
					</epic-option>
					}
				</epic-autocomplete>
			</epic-form-field>
			}
		</div>

		<div class="orders-filters__recipient form__element-3">
			@if (aclCanReadEntities()) {
			<epic-form-field [text]="textEntity">
				<epic-autocomplete
					[multiSelect]="true"
					[placeholder]="textSearchRecipient"
					(searchChange)="fetchEntities($event)"
					[control]="filtersFormGroup.controls.entity"
					[focused]="!!filtersFormGroup.controls.entity.value"
					[customSelectedValues]="true"
					class="form__element-7">
					<div
						class="epic-select__custom-values"
						selected-values>
						<span
							*ngFor="let entity of filtersFormGroup.controls.entity.value"
							class="epic-select__value-round"
							>{{ entity.name }}</span
						>
					</div>
					@for (entity of entities.items; track entity.id) {
					<epic-option
						[value]="entity"
						[selected]="checkIfContractorSelected(entity)"
						class="epic_select__option">
						{{ entity.name }}
					</epic-option>
					}
				</epic-autocomplete>
			</epic-form-field>
			}
		</div>
		<div class="form__element-12 row justify__end">
			<epic-button
				(onClick)="formElement.onSubmit($event)"
				size="large">
				<epic-icon name="document-2-black" />
				<span i18n>Show documents</span>
			</epic-button>
		</div>
	</form>
</epic-card>
<epic-card [isCollapseAllowed]="false">
	<div
		class="row gap--large"
		start>
		<span i18n>Document list</span>
		<div #buttonComponent>
			@if (aclCanCreateInvoices()) {
			<epic-button
				[classes]="['epic-button--square']"
				[epic-tooltip-classes]="['epic-tooltip--square']"
				[epic-tooltip]="textIssueAnInvoice">
				<epic-icon name="plus-mini" />
			</epic-button>
			}
		</div>
	</div>
	<epic-popover
		[parentReference]="buttonComponent"
		class="font__small"
		horizontalPlacement="right">
		<div
			class="epic-popover__header"
			popover-header>
			{{ textIssueAnInvoice }}
		</div>
		<div
			class="epic-popover__list"
			popover-content>
			<div
				(click)="createInvoice(InvoiceType.WithTax)"
				class="epic-popover__item">
				{{ textInvoiceWithVAT }}
			</div>
			<!-- TODO No Active -->
			<!--			<div class="epic-popover__item no-active">{{ textInvoiceWithoutVAT }}</div>-->
			<div
				(click)="createInvoice(InvoiceType.Proforma)"
				class="epic-popover__item">
				{{ textProformaInvoice }}
			</div>
		</div>
	</epic-popover>
	<!-- TODO no-active -->
	<div
		class="row align__center"
		end>
		<epic-pagination [pagination]="pagination" />
		<epic-icon-button
			class="no-active"
			hoverColor="var(--color__dark)"
			iconHoverColor="var(--color__white)"
			iconHoverQuerySelector="path"
			iconName="issue-kp"
			text="Lorem ipsum" />
		<epic-icon-button
			class="no-active"
			hoverColor="var(--color__dark)"
			iconHoverColor="var(--color__white)"
			iconHoverQuerySelector="path"
			iconName="issue-invoice"
			text="Lorem ipsum" />
		<epic-icon-button
			(click)="exportToCSV()"
			[text]="textDownloadDataInCsvFormat"
			hoverColor="var(--color__dark)"
			iconHoverColor="var(--color__white)"
			iconHoverQuerySelector="path"
			iconName="csv" />
		@if (aclCanDeleteInvoices()) {
		<epic-icon-button
			(click)="deleteAll()"
			[text]="textDelete"
			hoverColor="var(--color__danger)"
			iconHoverColor="var(--color__white)"
			iconHoverQuerySelector="path"
			iconName="delete" />
		}
	</div>
	<epic-table
		#tableComponent
		(checkboxesChange)="handleCheckboxesChanges($event)"
		(queryChange)="handleQueryChanges($event)"
		[generateCheckboxes]="true"
		(tableViewInit)="setTableViewSortValue()"
		[enableAdditionalScroll]="true"
		class="font__small margin__top">
		<tr
			[is-header-checkbox]="true"
			class="font__small font-weight__bold"
			table-headers
			table-row-data>
			<epic-table-header class="font-align__center">
				<span i18n="@@ordinalNumber">No.</span>
			</epic-table-header>
			<epic-table-header />
			<epic-table-header
				dataKey="document_number"
				sortable>
				<span i18n>Document number</span>
			</epic-table-header>
			<epic-table-header>
				<span i18n>Container number</span>
			</epic-table-header>
			<epic-table-header
				dataKey="issue_date"
				sortable>
				<span i18n>Date</span>
			</epic-table-header>
			<epic-table-header
				dataKey="customer_name"
				sortable>
				<span i18n>Contractor</span>
			</epic-table-header>
			<epic-table-header>
				<span i18n>Past Due</span>
			</epic-table-header>
			<epic-table-header>
				<span i18n>Payment date</span>
			</epic-table-header>
			<epic-table-header>
				<span i18n>Status</span>
			</epic-table-header>
			<epic-table-header>
				<div class="column full-width">
					<span i18n>Net</span>
					<div
						class="invoices__sum-indicator clickable"
						#summaryContainer
						[epic-tooltip]="textChangeSummaryCurrency"
						epic-tooltip-position="right">
						<span
							class="font-weight__regular"
							i18n>
							sum:
						</span>
						<span class="font-weight__bold">
							{{ getNetValueSum() | currency: summaryCurrency : 'symbol' : '0.2-2' : languageCode }}
						</span>
					</div>
				</div>
			</epic-table-header>
			<epic-table-header>
				<div class="column">
					<span i18n>VAT</span>
					<div class="invoices__sum-indicator">
						<span
							class="font-weight__regular"
							i18n>
							sum:
						</span>
						<span class="font-weight__bold">
							{{ getTaxValueSum() | currency: summaryCurrency : 'symbol' : '0.2-2' : languageCode }}
						</span>
					</div>
				</div>
			</epic-table-header>
			<epic-table-header>
				<div class="column">
					<span i18n>Gross</span>
					<div class="invoices__sum-indicator">
						<span
							class="font-weight__regular"
							i18n>
							sum:
						</span>
						<span class="font-weight__bold">
							{{ getGrossValueSum() | currency: summaryCurrency : 'symbol' : '0.2-2' : languageCode }}
						</span>
					</div>
				</div>
			</epic-table-header>
			<epic-table-header>
				<div class="column">
					<span i18n>Amount paid</span>
					<div class="invoices__sum-indicator">
						<span
							class="font-weight__regular"
							i18n>
							sum:
						</span>
						<span class="font-weight__bold">
							{{ getValuePaidSum() | currency: summaryCurrency : 'symbol' : '0.2-2' : languageCode }}
						</span>
					</div>
				</div>
			</epic-table-header>
			<epic-table-header>
				<div class="column">
					<span i18n>Amount due</span>
					<div class="invoices__sum-indicator">
						<span
							class="font-weight__regular"
							i18n>
							sum:
						</span>
						<span class="font-weight__bold">
							{{ getValueToPaySum() | currency: summaryCurrency : 'symbol' : '0.2-2' : languageCode }}
						</span>
					</div>
				</div>
			</epic-table-header>
			<epic-table-header class="table-column__xs" />
			<epic-table-header class="table-column__xs" />
		</tr>
		@for (item of items; track item) {
		<tr
			class="font__small"
			[class]="{'focus--border': getLocalStorageScroll?.scrollTargetId === item.id}"
			[class.bg-error]="getDaysDifference(item) > 0"
			[table-row-data]="item">
			<!-- Index -->
			<td class="table-column__xs font-align__center">{{ $index + 1 }}</td>
			<!-- Type -->
			<td class="font-align__center table-column__xs">
				@if (item.invoice_type?.key == InvoiceType.WithTax) {
				<span
					class="invoices__status-tag invoices__status-tag--with-tax font__small"
					i18n="@@invoiceTypeWithTax"
					[epic-tooltip]="textInvoiceWithVAT"
					epic-tooltip-position="right">
					V
				</span>
				} @else if (item.invoice_type?.key == InvoiceType.WithoutTax) {
				<span
					class="invoices__status-tag invoices__status-tag--without-tax font__small"
					i18n="@@invoiceTypeWithoutTax"
					[epic-tooltip]="textInvoiceWithoutVAT"
					epic-tooltip-position="right">
					N
				</span>
				} @else if (item.invoice_type?.key == InvoiceType.Correction) {
				<span
					class="invoices__status-tag invoices__status-tag--correction font__small"
					i18n="@@invoiceTypeCorrection"
					[epic-tooltip]="textCorrection"
					epic-tooltip-position="right">
					C
				</span>
				} @else if (item.invoice_type?.key == InvoiceType.Proforma) {
				<span
					class="invoices__status-tag invoices__status-tag--proforma font__small"
					i18n="@@invoiceTypeProforma"
					[epic-tooltip]="textProformaInvoice"
					epic-tooltip-position="right">
					P
				</span>
				}
			</td>
			<!-- Document number -->
			<td class="table-column__sm">
				@if (item.status == 'DRAFT') {
				<div class="invoices__status-tag invoices__status-tag--draft">
					<div class="row align__center justify__space-between">
						<div>Draft invoice</div>
						<epic-icon
							name="info-circle"
							[epic-tooltip]="textDraftTooltip" />
					</div>
				</div>
				} @else {
				<span class="row align__center gap">
					<span
						class="clickable"
						(click)="handleInvoiceDetails(item.id)">
						{{ item.document_number }}
					</span>
					@if (item.is_created_automatically) {
					<span
						class="is-created-automatically-icon"
						[epic-tooltip]="textIsCreatedAutomatically">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 22 22">
							<g
								id="Group_647"
								data-name="Group 647"
								transform="translate(-6 -6)">
								<path
									id="Path_47892"
									data-name="Path 47892"
									d="M2.2,0H.152l3.8-9.521H6.011L9.8,0H7.75L6.97-2.044H2.983ZM3.625-3.758h2.71L4.977-7.287Z"
									transform="translate(11.848 21.521)"
									fill="#212121" />
								<g
									id="Ellipse_4"
									data-name="Ellipse 4"
									transform="translate(6 6)"
									fill="none"
									stroke="#212121"
									stroke-width="1">
									<circle
										cx="11"
										cy="11"
										r="11"
										stroke="none" />
									<circle
										cx="11"
										cy="11"
										r="10.5"
										fill="none" />
								</g>
							</g>
						</svg>
					</span>
					}
				</span>
				}
			</td>
			<td class="table-column__sm">
				@if (item.orders_minimal && item.orders_minimal.length > 0) {
				<span
					class="clickable"
					(click)="goToOrder(item.orders_minimal[0].order_id ?? '')"
					>{{ item.orders_minimal[0].order_container_number }}</span
				>
				} @else { {{ item.container_number }} }
			</td>
			<!-- Date -->
			<td class="table-column__sm">{{ item.issue_date | date : 'dd.MM.YYYY' }}</td>
			<!-- Contractor -->
			<td>{{ item.customer_name }}</td>
			<!-- Payment due date -->
			<td
				class="font-align__center table-column__sm font-weight__bold"
				[class.text-color__danger]="getDaysDifference(item) > 0">
				@if (getDaysDifference(item) > 0) { {{ getDaysDifference(item) }} } @else { - }
			</td>
			<td class="table-column__sm">
				@if (item.payment_date) { {{ item.payment_date | date : 'dd.MM.YYYY' }} } @else {
				<div #showDatePickerButton>
					<epic-icon-button
						(click)="resetDateControls()"
						[hoverColor]="'var(--color__dark)'"
						[iconHoverColor]="'var(--color__white)'"
						[tooltipTextColor]="'var(--color__white)'"
						[iconHoverQuerySelector]="'path'"
						[text]="textEnterPaymentDate"
						[iconName]="'calendar'" />
				</div>
				<epic-popover [parentReference]="showDatePickerButton">
					<div
						popover-header
						class="text-no-wrap font__small padding__right"
						i18n>
						Change payment date
					</div>
					<div
						class="epic-popover__list margin--medium"
						popover-content>
						<div>
							<epic-form-field
								[text]="textEnterPaymentDate"
								class="form__element-2"
								datePicker>
								<epic-input
									[control]="dateControl"
									[placeholder]="textSelect" />
							</epic-form-field>
						</div>
						<div class="row-reverse margin__top--medium">
							<epic-button
								(onClick)="changePaymentDate(item)"
								size="large">
								<epic-icon name="document-2-black" />
								<span i18n>Confirm</span>
							</epic-button>
						</div>
					</div>
				</epic-popover>
				}
			</td>
			<td class="table-column__sm">
				<epic-tag
					(click)="changeStatus(item)"
					[classes]="['tag--small', 'tag--readonly']"
					[borderColor]="'var(--color__white)'"
					[background]="item.payment_status?.is_paid? 'var(--color__success)': item.payment_status?.is_partially_paid? 'var(--color__warning)': 'var(--color__danger)'"
					[hoverColor]="item.payment_status?.is_paid? 'var(--color__success)': item.payment_status?.is_partially_paid? 'var(--color__warning)': 'var(--color__danger)'"
					[color]="'var(--color__white)'"
					[text]="getItemTranslation(item.payment_status)" />
			</td>
			<td class="font-align__right table-column__sm">
				{{ getNetValue(item.items, item?.relates_to?.items) | currency: item.currency : 'symbol' : '0.2-2' :
				languageCode }}
			</td>
			<td class="font-align__right table-column__sm">
				{{ getTaxValue(item.items, item?.relates_to?.items) | currency: item.currency : 'symbol' : '0.2-2' :
				languageCode }}
			</td>
			<td class="font-align__right table-column__sm">
				{{ getGrossValue(item.items, item?.relates_to?.items) | currency: item.currency : 'symbol' : '0.2-2' :
				languageCode }}
			</td>
			<td class="font-align__right table-column__sm">
				{{ getValuePaid(item) | currency: item.currency : 'symbol' : '0.2-2' : languageCode }}
			</td>
			<td class="font-align__right table-column__sm">
				{{ getValueToPay(item) | currency: item.currency : 'symbol' : '0.2-2' : languageCode }}
			</td>
			<td class="invoices__buttons-wrap">
				<div class="invoices__buttons">
					<epic-icon-button
						[text]="textInvoiceDetails"
						(click)="handleInvoiceDetails(item.id)"
						[hoverColor]="'var(--color__dark)'"
						[iconHoverColor]="'var(--color__white)'"
						[iconName]="'eye'"
						[tooltipTextColor]="'var(--color__white)'"
						iconHoverQuerySelector="path" />
					<div #downloadPdfElement>
						<epic-icon-button
							[text]="textDownloadPdf"
							[hoverColor]="'var(--color__dark)'"
							[iconHoverColor]="'var(--color__white)'"
							[tooltipTextColor]="'var(--color__white)'"
							[iconHoverQuerySelector]="'path'"
							[iconName]="'file-pdf'" />
					</div>
					<epic-icon-button
						[text]="textDuplicateDocument"
						hoverColor="var(--color__dark)"
						iconHoverColor="var(--color__white)"
						iconHoverQuerySelector="path"
						iconName="duplicate-document"
						(click)="duplicateDocument(item)" />
					@if (canCreateCashDisbursement(item)) {
					<epic-icon-button
						(click)="createCashDisbursement(item)"
						[text]="textCreateCashDisbursement"
						[hoverColor]="'var(--color__dark)'"
						[iconHoverColor]="'var(--color__white)'"
						[tooltipTextColor]="'var(--color__white)'"
						[iconHoverQuerySelector]="'path'"
						iconName="issue-kw" />
					}
				</div>
			</td>
			<td
				class="c-pointer table-column__xs"
				#actions>
				<epic-icon name="more" />
			</td>
			<epic-popover
				[parentReference]="actions"
				(afterClosed)="downloadPdfPopoverComponent.closePopover();sendAnInvoicePopoverComponent.closePopover()">
				<div
					popover-header
					i18n>
					Invoice {{ item.document_number }}
				</div>
				<div
					class="epic-popover__list"
					popover-content>
					<!-- TODO no-active -->
					@if (aclCanUpdateInvoices()) {
					<div
						(click)="changeStatus(item)"
						class="epic-popover__item"
						i18n>
						Change status
					</div>
					} @if (item.invoice_type?.key == InvoiceType.WithTax && aclCanUpdateInvoices()) {
					<div
						(click)="invoicesHelperService.issueCorrection(item)"
						class="epic-popover__item"
						i18n>
						Issue a correction
					</div>
					} @if (item.invoice_type?.key == InvoiceType.Proforma) {
					<div
						(click)="invoicesHelperService.issueVatInvoiceBasedOnProforma(item)"
						class="epic-popover__item"
						i18n>
						Issue a VAT invoice
					</div>
					}
					<div
						#sendAnInvoiceElement
						(click)="downloadPdfPopoverComponent.closePopover()"
						class="epic-popover__item"
						i18n>
						Send an invoice
					</div>
					@if (aclCanUpdateInvoices()) {
					<div
						(click)="edit(item)"
						class="epic-popover__item"
						i18n>
						Edit
					</div>
					} @if (aclCanUpdateInvoices()) {
					<div
						(click)="createCashReceipt(item)"
						class="epic-popover__item"
						i18n>
						Create cash receipt
					</div>
					<div
						(click)="createTransfer(item)"
						class="epic-popover__item epic-popover__item--danger"
						i18n>
						Create transfer
					</div>
					} @if (aclCanDeleteInvoices()) {
					<div
						(click)="delete(item)"
						class="epic-popover__item epic-popover__item--danger"
						i18n>
						Delete
					</div>
					}
				</div>
			</epic-popover>
			<epic-popover
				#downloadPdfPopoverComponent
				[parentReference]="downloadPdfElement">
				<div
					popover-header
					i18n>
					Select the invoice language
				</div>
				<div
					class="epic-popover__list"
					popover-content>
					@for (languageCode of appConfig.invoiceLanguages; track languageCode) {
					<epic-language-switcher-element
						[languageCode]="languageCode"
						(click)="downloadPDF(item, languageCode);downloadPdfPopoverComponent.closePopover();$event.stopPropagation()" />
					}
				</div>
			</epic-popover>
			<epic-popover
				#sendAnInvoicePopoverComponent
				[parentReference]="sendAnInvoiceElement">
				<div
					popover-header
					i18n>
					Select the invoice language
				</div>
				<div
					class="epic-popover__list"
					popover-content>
					@for (languageCode of appConfig.invoiceLanguages; track languageCode) {
					<epic-language-switcher-element
						[languageCode]="languageCode"
						(click)="sendAnInvoice(item, languageCode);sendAnInvoicePopoverComponent.closePopover();$event.stopPropagation()" />
					}
				</div>
			</epic-popover>
		</tr>
		}
	</epic-table>
	<div class="row align__center justify__end">
		<epic-pagination [pagination]="pagination" />
		<epic-icon-button
			(click)="scrollTo(topPageHtmlElement)"
			iconName="arrow-up" />
	</div>
</epic-card>

<epic-popover
	[parentReference]="summaryContainer"
	class="font__small">
	<div
		popover-header
		i18n>
		Currency
	</div>
	<div
		class="epic-popover__list"
		popover-content>
		@for (currency of availableCurrencies; track $index) {
		<div
			(click)="onSummaryCurrencyClick(currency)"
			class="epic-popover__item"
			i18n>
			{{ currency }}
		</div>
		}
	</div>
</epic-popover>
